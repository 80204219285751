import { Controller } from '@hotwired/stimulus';
import { post } from '@rails/request.js';

import type { BarcodeDetectorPolyfill, DetectedBarcode } from '../utilities/load-barcode-polyfill';

export default class StaffAttendanceScannerController extends Controller<HTMLElement> {
  static values = {
    task: String,
    location: String,
    date: String,
  };

  static targets = ['video', 'loading', 'message', 'inputList'];

  declare readonly inputListTarget: HTMLSelectElement;
  declare readonly videoTarget: HTMLVideoElement;
  declare readonly loadingTarget: HTMLElement;
  declare readonly messageTarget: HTMLElement;
  declare readonly taskValue: string;
  declare readonly locationValue: string;
  declare readonly dateValue: string;

  mediaStream?: MediaStream;
  barcodeDetectorPolyfill?: typeof BarcodeDetectorPolyfill;
  barcodeDetector?: BarcodeDetectorPolyfill;
  scanLoop?: number;

  enumerateDevicesBinding?: () => void;

  initialize() {
    this.enumerateDevicesBinding = this.enumerateDevices.bind(this);
  }

  connect() {
    void this.enumerateDevices();

    navigator.mediaDevices.addEventListener('devicechange', this.enumerateDevicesBinding!);

    void import('../utilities/load-barcode-polyfill').then(({ BarcodeDetectorPolyfill }) => {
      this.barcodeDetectorPolyfill = BarcodeDetectorPolyfill;

      void this.startScanning();
    });
  }

  disconnect() {
    this.stopScanning();

    navigator.mediaDevices.removeEventListener('devicechange', this.enumerateDevicesBinding!);
  }

  async startScanning() {
    this.loadingTarget.style.display = 'flex';
    this.messageTarget.style.display = 'none';
    this.videoTarget.style.display = 'none';

    const width = this.loadingTarget.offsetWidth;
    const height = this.loadingTarget.offsetHeight;

    const multiplier = width < 1280 && height < 1280 ? 2 : 1;

    this.loadingTarget.style.display = 'none';
    this.videoTarget.style.display = 'block';

    this.mediaStream = await navigator.mediaDevices.getUserMedia({
      video: {
        width: { ideal: width * multiplier },
        height: { ideal: height * multiplier },
        deviceId: this.videoSource ? { exact: this.videoSource } : undefined,
      },
    });
    this.videoTarget.srcObject = this.mediaStream;

    if (this.barcodeDetectorPolyfill) {
      this.barcodeDetector = new this.barcodeDetectorPolyfill({ formats: ['qr_code', 'code_39'] });
    }

    this.setCameraOrientation();

    // Enumerate devices again after we have permission
    void this.enumerateDevices();

    void this.tryScanning();
  }

  stopScanning() {
    clearTimeout(this.scanLoop);

    if (this.mediaStream) {
      for (const track of this.mediaStream.getTracks()) {
        track.stop();
      }
    }

    if (this.videoTarget) {
      this.videoTarget.srcObject = null;
    }

    this.barcodeDetector = undefined;

    this.loadingTarget.style.display = 'flex';
    this.videoTarget.style.display = 'none';
  }

  setCameraOrientation() {
    const cameraTitle = this.inputListTarget.options[this.inputListTarget.selectedIndex].text;

    this.videoTarget.style.transform = cameraTitle.toLowerCase().includes('front', 0) ? 'scaleX(-1)' : '';
  }

  changeInput() {
    this.stopScanning();
    void this.startScanning();
  }

  fullScreen() {
    void this.element.requestFullscreen();
  }

  get videoSource() {
    return this.inputListTarget.value;
  }

  enumerateDevices() {
    void navigator.mediaDevices.enumerateDevices().then(this.gotDevices.bind(this));
  }

  gotDevices(devices: MediaDeviceInfo[]) {
    const currentDeviceId = this.inputListTarget.value;
    this.inputListTarget.innerHTML = '';

    for (const device of devices) {
      if (device.kind === 'videoinput') {
        const option = document.createElement('option');
        option.value = device.deviceId;
        option.text = device.label || `Camera ${this.inputListTarget.options.length + 1}`;
        this.inputListTarget.append(option);
      }
    }

    if (currentDeviceId) {
      this.inputListTarget.value = currentDeviceId;
      this.setCameraOrientation();
    }
  }

  async tryScanning() {
    if (!this.barcodeDetector) {
      this.scanLoop = setTimeout(this.tryScanning.bind(this), 200);
      return;
    }

    try {
      // Try to detect barcodes in the current video frame.
      const barcodes = await this.barcodeDetector.detect(this.videoTarget);

      // Continue loop if no barcode was found.
      if (barcodes.length === 0) {
        this.scanLoop = setTimeout(this.tryScanning.bind(this), 50);
        return;
      }

      void this.processBarcode(barcodes[0]);
    } catch {
      this.scanLoop = setTimeout(this.tryScanning.bind(this), 200);
    }
  }

  async imageData() {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (!context) {
      throw new Error('Could not get 2D context');
    }

    canvas.width = this.videoTarget.videoWidth;
    canvas.height = this.videoTarget.videoHeight;
    context.drawImage(this.videoTarget, 0, 0, canvas.width, canvas.height);

    return new Promise<Blob>((resolve) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            throw new Error('Could not create blob');
          }

          resolve(blob);
        },
        'image/jpeg',
        0.8,
      );
    });
  }

  async processBarcode(barcode: DetectedBarcode) {
    const image = await this.imageData();
    this.stopScanning();

    try {
      const formData = new FormData();

      formData.append('bounding_box', JSON.stringify(barcode.boundingBox));
      formData.append('barcode', barcode.rawValue);
      formData.append('task', this.taskValue);
      formData.append('location', this.locationValue);
      formData.append('date', this.dateValue);
      formData.append('image', image, 'image.jpg');

      await post('/staff_attendance/check_in_out/process', {
        body: formData,
      });

      this.loadingTarget.style.display = 'none';
      this.messageTarget.style.display = 'flex';
    } catch (error: unknown) {
      if (error instanceof Error) {
        alert(`Error processing QR code: ${error.message}`);
      }
    }
  }

  closeMessage() {
    this.messageTarget.style.display = 'none';
    void this.startScanning();
  }
}
