import Timeago from '@stimulus-components/timeago';

import AppearController from './appear-controller';
import { application } from './application';
import AutogrowController from './autogrow-controller';
import AutosubmitController from './autosubmit-controller';
import ChartsCountsController from './charts-counts-controller';
import CheckboxSelectController from './checkbox-select-controller';
import ColorPickerController from './color-picker-controller';
import CommandMenuController from './command-menu-controller';
import DelayedRemoveController from './delayed-remove-controller';
import DialogController from './dialog-controller';
import DropdownController from './dropdown-controller';
import GeocoderCacheController from './geocoder-cache-controller';
import MultiDatePickerController from './multi-date-picker-controller';
import NavbarController from './navbar-controller';
import NearbyLocationsController from './nearby-locations-controller';
import ProgressController from './progress-controller';
import ProjectionsController from './projections-controller';
import RemoveController from './remove-controller';
import ReportController from './report-controller';
import ScheduleActivityController from './schedule-activity-controller';
import ScheduleAssignmentController from './schedule-assignment-controller';
import ScheduleDayController from './schedule-day-controller';
import ScheduleDaySelectController from './schedule-day-select-controller';
import ScheduleFrequencyChartController from './schedule-frequency-chart-controller';
import ScheduleGroupController from './schedule-group-controller';
import ScheduleLocationController from './schedule-location-controller';
import SidebarController from './sidebar-controller';
import StaffAttendanceScannerController from './staff-attendance-scanner-controller';
import TransactionSummaryController from './transaction-summary-controller';
import TurboFrameRefreshController from './turbo-frame-refresh-controller';

application.register('appear', AppearController);
application.register('autogrow', AutogrowController);
application.register('autosubmit', AutosubmitController);
application.register('charts-counts', ChartsCountsController);
application.register('checkbox-select', CheckboxSelectController);
application.register('color-picker', ColorPickerController);
application.register('command-menu', CommandMenuController);
application.register('delayed-remove', DelayedRemoveController);
application.register('dialog', DialogController);
application.register('dropdown', DropdownController);
application.register('geocoder-cache', GeocoderCacheController);
application.register('multi-date-picker', MultiDatePickerController);
application.register('navbar', NavbarController);
application.register('nearby-locations', NearbyLocationsController);
application.register('progress', ProgressController);
application.register('projections', ProjectionsController);
application.register('remove', RemoveController);
application.register('report', ReportController);
application.register('schedule-activity', ScheduleActivityController);
application.register('schedule-assignment', ScheduleAssignmentController);
application.register('schedule-day-select', ScheduleDaySelectController);
application.register('schedule-day', ScheduleDayController);
application.register('schedule-frequency-chart', ScheduleFrequencyChartController);
application.register('schedule-group', ScheduleGroupController);
application.register('schedule-location', ScheduleLocationController);
application.register('sidebar', SidebarController);
application.register('staff-attendance-scanner', StaffAttendanceScannerController);
application.register('timeago', Timeago);
application.register('transaction-summary', TransactionSummaryController);
application.register('turbo-frame-refresh', TurboFrameRefreshController);
