import { Controller } from '@hotwired/stimulus';

export default class CheckboxSelectController extends Controller {
  declare readonly hasCheckboxAllTarget: boolean;
  declare readonly checkboxTargets: HTMLInputElement[];
  declare readonly checkboxAllTarget: HTMLInputElement;
  declare readonly disableIndeterminateValue: boolean;

  static targets = ['checkboxAll', 'checkbox'];

  static values = {
    disableIndeterminate: {
      type: Boolean,
      default: false,
    },
  };

  declare boundToggle: EventListener;
  declare boundRefresh: EventListener;

  initialize() {
    this.boundToggle = this.toggle.bind(this);
    this.boundRefresh = this.refresh.bind(this);
  }

  checkboxAllTargetConnected(checkbox: HTMLInputElement): void {
    checkbox.addEventListener('change', this.boundToggle);

    this.refresh();
  }

  checkboxTargetConnected(checkbox: HTMLInputElement): void {
    checkbox.addEventListener('change', this.boundRefresh);

    this.refresh();
  }

  checkboxAllTargetDisconnected(checkbox: HTMLInputElement): void {
    checkbox.removeEventListener('change', this.boundToggle);

    this.refresh();
  }

  checkboxTargetDisconnected(checkbox: HTMLInputElement): void {
    checkbox.removeEventListener('change', this.boundRefresh);

    this.refresh();
  }

  toggle(event: Event): void {
    event.preventDefault();

    const target = event.target as HTMLInputElement;

    if (target) {
      for (const checkbox of this.checkboxTargets) {
        checkbox.checked = target.checked;
        this.triggerInputEvent(checkbox);
      }
    }
  }

  selectAll(): void {
    for (const checkbox of this.unchecked) {
      checkbox.checked = true;
      this.triggerInputEvent(checkbox);
    }
  }

  selectNone(): void {
    for (const checkbox of this.checked) {
      checkbox.checked = false;
      this.triggerInputEvent(checkbox);
    }
  }

  refresh(): void {
    const checkboxesCount = this.checkboxTargets.length;
    const checkboxesCheckedCount = this.checked.length;

    if (this.disableIndeterminateValue) {
      this.checkboxAllTarget.checked = checkboxesCheckedCount === checkboxesCount;
    } else {
      this.checkboxAllTarget.checked = checkboxesCheckedCount > 0;
      this.checkboxAllTarget.indeterminate = checkboxesCheckedCount > 0 && checkboxesCheckedCount < checkboxesCount;
    }
  }

  triggerInputEvent(checkbox: HTMLInputElement): void {
    const event = new Event('input', { bubbles: false, cancelable: true });

    checkbox.dispatchEvent(event);
  }

  get checked(): HTMLInputElement[] {
    return this.checkboxTargets.filter((checkbox) => checkbox.checked);
  }

  get unchecked(): HTMLInputElement[] {
    return this.checkboxTargets.filter((checkbox) => !checkbox.checked);
  }
}
